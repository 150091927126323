@font-face {
  font-family: 'Papam-Pei';
  src:  url('fonts/Papam-Pei.eot?cua5kk');
  src:  url('fonts/Papam-Pei.eot?cua5kk#iefix') format('embedded-opentype'),
    url('fonts/Papam-Pei.ttf?cua5kk') format('truetype'),
    url('fonts/Papam-Pei.woff?cua5kk') format('woff'),
    url('fonts/Papam-Pei.svg?cua5kk#Papam-Pei') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="papampei-"], [class*=" papampei-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Papam-Pei' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.papampei-previous:before {
  content: "\e912";
}
.papampei-next:before {
  content: "\e913";
}
.papampei-loading:before {
  content: "\e911";
}
.papampei-download:before {
  content: "\e910";
}
.papampei-checkbox_checked:before {
  content: "\e90e";
}
.papampei-checkbox:before {
  content: "\e90f";
}
.papampei-close:before {
  content: "\e90d";
}
.papampei-send:before {
  content: "\e90b";
}
.papampei-plus:before {
  content: "\e90c";
}
.papampei-tasks:before {
  content: "\e90a";
}
.papampei-locker:before {
  content: "\e909";
}
.papampei-menu:before {
  content: "\e908";
}
.papampei-contact:before {
  content: "\e906";
}
.papampei-key:before {
  content: "\e907";
}
.papampei-calendar:before {
  content: "\e903";
}
.papampei-pin:before {
  content: "\e904";
}
.papampei-search:before {
  content: "\e905";
}
.papampei-arrow-left:before {
  content: "\e901";
}
.papampei-arrow-right:before {
  content: "\e902";
}
.papampei-user:before {
  content: "\e900";
}
